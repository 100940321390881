import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ref, onValue, push, set, remove, update } from 'firebase/database';
import database from './firebase';
import HomePage from './components/HomePage';
import CategoryPage from './components/CategoryPage';
import ServicePage from './components/ServicePage';
import AddServicePage from './components/AddServicePage';
import AdminPanel from './components/AdminPanel';
import ModerationPage from './components/ModerationPage';
import ManageServicesPage from './components/ManageServicesPage';
import ManageCategoriesPage from './components/ManageCategoriesPage';
import SearchBar from './components/SearchBar';
import { useTelegramWebApp } from './hooks/useTelegramWebApp';
import { useTelegramUser } from './hooks/useTelegramUser';
import './App.css';

function App() {
  const [services, setServices] = useState([]);
  const [pendingServices, setPendingServices] = useState([]);
  const [categories, setCategories] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const webApp = useTelegramWebApp();
  const user = useTelegramUser();

  useEffect(() => {
    const servicesRef = ref(database, 'services');
    const pendingServicesRef = ref(database, 'pendingServices');
    const categoriesRef = ref(database, 'categories');

    const unsubscribeServices = onValue(servicesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const servicesArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setServices(servicesArray);
      } else {
        setServices([]);
      }
    });

    const unsubscribePendingServices = onValue(pendingServicesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const pendingServicesArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setPendingServices(pendingServicesArray);
      } else {
        setPendingServices([]);
      }
    });

    const unsubscribeCategories = onValue(categoriesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCategories(data);
      } else {
        setCategories({});
      }
    });

    // Проверка на админа
     if (user && user.username === 'iGray2') {
        setIsAdmin(true);
    } else {
        setIsAdmin(false);
    }

    return () => {
      unsubscribeServices();
      unsubscribePendingServices();
      unsubscribeCategories();
    };
  }, [user]);

    const addService = (newService) => {
    const pendingServicesRef = ref(database, 'pendingServices');
    const newServiceRef = push(pendingServicesRef);
    set(newServiceRef, {
      ...newService,
      status: 'pending'
    }).then(() => {
      console.log('New service added successfully');
    }).catch((error) => {
      console.error('Error adding new service:', error);
    });
  };

  const approveService = (serviceId) => {
    const pendingServiceRef = ref(database, `pendingServices/${serviceId}`);
    const approvedServicesRef = ref(database, 'services');
    
    onValue(pendingServiceRef, (snapshot) => {
      const serviceData = snapshot.val();
      if (serviceData) {
        const newApprovedServiceRef = push(approvedServicesRef);
        set(newApprovedServiceRef, {
          ...serviceData,
          rating: 0,
          ratingCount: 0
        }).then(() => {
          remove(pendingServiceRef);
          console.log('Service approved and moved successfully');
        }).catch((error) => {
          console.error('Error approving service:', error);
        });
      }
    }, { onlyOnce: true });
  };

  const rejectService = (serviceId) => {
    const pendingServiceRef = ref(database, `pendingServices/${serviceId}`);
    remove(pendingServiceRef).then(() => {
      console.log('Service rejected and removed successfully');
    }).catch((error) => {
      console.error('Error rejecting service:', error);
    });
  };

  const deleteService = (serviceId) => {
    const serviceRef = ref(database, `services/${serviceId}`);
    remove(serviceRef).then(() => {
      console.log('Service deleted successfully');
    }).catch((error) => {
      console.error('Error deleting service:', error);
    });
  };

  const updateRating = (serviceId, newRating) => {
    const serviceRef = ref(database, `services/${serviceId}`);
    const userRatingRef = ref(database, `services/${serviceId}/userRatings/${user.id}`);
    
    onValue(serviceRef, (snapshot) => {
        const serviceData = snapshot.val();
        if (serviceData) {
            const currentRating = serviceData.rating || 0;
            const currentCount = serviceData.ratingCount || 0;
            const userRatings = serviceData.userRatings || {};
            const previousUserRating = userRatings[user.id];

            let updatedRating, newCount;
            
            if (previousUserRating) {
                // Пользователь уже голосовал, нужно обновить его оценку
                updatedRating = ((currentRating * currentCount) - previousUserRating + newRating) / currentCount;
                newCount = currentCount;
            } else {
                // Пользователь голосует впервые
                updatedRating = ((currentRating * currentCount) + newRating) / (currentCount + 1);
                newCount = currentCount + 1;
            }

            // Обновляем рейтинг и сохраняем оценку пользователя
            update(serviceRef, {
                rating: updatedRating,
                ratingCount: newCount,
                [`userRatings/${user.id}`]: newRating
            }).then(() => {
                console.log('Rating updated successfully');
            }).catch((error) => {
                console.error('Error updating rating:', error);
            });
        }
     }, { onlyOnce: true });
  };

  const addCategory = (categoryName) => {
    const categoriesRef = ref(database, 'categories');
    const newCategoryRef = push(categoriesRef);
    set(newCategoryRef, categoryName)
      .then(() => {
        console.log('New category added successfully');
      })
      .catch((error) => {
        console.error('Error adding new category:', error);
      });
  };

  const deleteCategory = (categoryId) => {
    const categoryRef = ref(database, `categories/${categoryId}`);
    remove(categoryRef)
      .then(() => {
        console.log('Category deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting category:', error);
      });
  };

  const addComment = (serviceId, commentText, parentId = null) => {
    console.log('Adding comment:', { serviceId, commentText, parentId });
    const commentsRef = ref(database, `comments/${serviceId}`);
    const newCommentRef = push(commentsRef);
    set(newCommentRef, {
      text: commentText,
      timestamp: Date.now()
    }).then(() => {
      console.log('Comment added successfully');
    }).catch((error) => {
      console.error('Error adding comment:', error);
    });
  };

  const deleteComment = (serviceId, commentId) => {
    const commentRef = ref(database, `comments/${serviceId}/${commentId}`);
    remove(commentRef).then(() => {
      console.log('Комментарий успешно удален');
    }).catch((error) => {
      console.error('Ошибка при удалении комментария:', error);
    });
  };

   return (
    <Router>
      <div className="App">
        {user && (
          <div className="user-info">
            <p>Добро пожаловать, {user.first_name}!</p>
            {user.username && <p>Ваш username: @{user.username}</p>}
          </div>
        )}
        <SearchBar services={services} />
        <Routes>
          <Route path="/" element={
            <>
              <HomePage categories={categories} />
              {isAdmin && <Link to="/admin" className="admin-link">Панель администратора</Link>}
            </>
          } />
          <Route 
            path="/category/:categoryId" 
            element={<CategoryPage services={services} categories={categories} />} 
          />
          <Route 
            path="/service/:serviceId" 
            element={
              <ServicePage 
                services={services} 
                updateRating={updateRating} 
                isAdmin={isAdmin}
                addComment={addComment}
                deleteComment={deleteComment}
              />
            } 
          />
          <Route 
            path="/add-service" 
            element={<AddServicePage onAddService={addService} categories={categories} />} 
          />
          {isAdmin && (
            <Route path="/admin" element={<AdminPanel />}>
              <Route 
                path="moderation" 
                element={
                  <ModerationPage 
                    pendingServices={pendingServices} 
                    approveService={approveService} 
                    rejectService={rejectService} 
                    categories={categories}
                  />
                } 
              />
              <Route
                path="manage-services"
                element={
                  <ManageServicesPage
                    services={services}
                    onDeleteService={deleteService}
                  />
                }
              />
              <Route
                path="manage-categories"
                element={
                  <ManageCategoriesPage
                    categories={categories}
                    onAddCategory={addCategory}
                    onDeleteCategory={deleteCategory}
                  />
                }
              />
            </Route>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
