import React from 'react';

const ModerationPage = ({ pendingServices, approveService, rejectService, categories }) => {
  if (!pendingServices || pendingServices.length === 0) {
    return <p>Загрузка данных или нет сервисов на модерации.</p>;
  }

  return (
    <div className="moderation-page">
      <h2>Модерация сервисов</h2>
      <ul>
        {pendingServices.map(service => (
          <li key={service.id}>
            <h3>{service.name}</h3>
            <p>{service.description}</p>
            <p>Цена: {service.price}</p>
            <p>Категория: {categories[service.category]}</p>
            <a href={service.serviceUrl} target="_blank" rel="noopener noreferrer">Ссылка на сервис</a>
            <div className="user-info">
              <h4>Добавлено пользователем:</h4>
              <p>ID: {service.addedBy.id}</p>
              <p>Username: {service.addedBy.username || 'Не указан'}</p>
              <p>Имя: {service.addedBy.firstName} {service.addedBy.lastName}</p>
            </div>
            <button onClick={() => approveService(service.id)}>Одобрить</button>
            <button onClick={() => rejectService(service.id)}>Отклонить</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModerationPage;