import { useState, useEffect } from 'react';

   export const useTelegramWebApp = () => {
     const [webApp, setWebApp] = useState(null);

     useEffect(() => {
       const tg = window.Telegram.WebApp;
       if (tg) {
         tg.ready();
         setWebApp(tg);
       }
     }, []);

     return webApp;
   };