import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const AdminPanel = () => {
  return (
    <div className="admin-panel">
      <h1>Панель администратора</h1>
      <nav>
        <ul>
          <li><Link to="/admin/moderation">Модерация сервисов</Link></li>
          <li><Link to="/admin/manage-services">Управление сервисами</Link></li>
          <li><Link to="/admin/manage-categories">Управление категориями</Link></li>
        </ul>
      </nav>
      <Outlet />
      <Link to="/">Вернуться на главную</Link>
    </div>
  );
};

export default AdminPanel;