import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ services }) => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    const results = services.filter(service => 
      service.name.toLowerCase().includes(query.toLowerCase())
    );
    if (results.length > 0) {
      navigate(`/service/${results[0].id}`);
    } else {
      alert('Сервис не найден');
    }
  };

  return (
    <form onSubmit={handleSearch} className="search-bar">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Поиск сервисов..."
      />
      <button type="submit">Поиск</button>
    </form>
  );
};

export default SearchBar;