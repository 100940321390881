import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ref, onValue, push, set, remove } from 'firebase/database';
import database from '../firebase';
import { useTelegramUser } from '../hooks/useTelegramUser';

const ServicePage = ({ updateRating, isAdmin }) => {
  const { serviceId } = useParams();
  const [service, setService] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [sortOrder, setSortOrder] = useState('newest');
  const user = useTelegramUser();

  useEffect(() => {
    const serviceRef = ref(database, `services/${serviceId}`);
    const unsubscribeService = onValue(serviceRef, (snapshot) => {
      setService(snapshot.val());
    });

    const commentsRef = ref(database, `comments/${serviceId}`);
    const unsubscribeComments = onValue(commentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const commentsArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setComments(sortComments(commentsArray, sortOrder));
      } else {
        setComments([]);
      }
    });

    return () => {
      unsubscribeService();
      unsubscribeComments();
    };
  }, [serviceId, sortOrder]);

  const sortComments = (commentsToSort, order) => {
    return [...commentsToSort].sort((a, b) => {
      if (order === 'newest') return b.timestamp - a.timestamp;
      if (order === 'oldest') return a.timestamp - b.timestamp;
      return 0;
    });
  };

  const handleAddComment = () => {
    if (newComment.trim() !== '' && user) {
      const commentData = {
        text: newComment,
        userId: user.id,
        username: user.username || `${user.first_name} ${user.last_name || ''}`.trim(),
        timestamp: Date.now()
      };
      const commentsRef = ref(database, `comments/${serviceId}`);
      const newCommentRef = push(commentsRef);
      set(newCommentRef, commentData).then(() => {
        console.log('Comment added successfully');
        setNewComment('');
      }).catch((error) => {
        console.error('Error adding comment:', error);
      });
    }
  };

  const handleDeleteComment = (commentId) => {
    const commentRef = ref(database, `comments/${serviceId}/${commentId}`);
    remove(commentRef).then(() => {
      console.log('Comment deleted successfully');
    }).catch((error) => {
      console.error('Error deleting comment:', error);
    });
  };

  const handleRate = (newRating) => {
    updateRating(serviceId, newRating);
  };

  const renderComments = () => {
    return comments.map(comment => (
      <div key={comment.id} className="comment">
        <p><strong>{comment.username || 'Аноним'}:</strong> {comment.text}</p>
        <small>{new Date(comment.timestamp).toLocaleString()}</small>
        {(isAdmin || (user && user.id === comment.userId)) && (
          <button onClick={() => handleDeleteComment(comment.id)}>Удалить</button>
        )}
      </div>
    ));
  };

  if (!service) {
    return <div>Загрузка сервиса...</div>;
  }

  return (
    <div className="service-page-container">
      <div className="service-page-content">
        <h1>{service.name}</h1>
        <div className="service-details">
          <p>{service.description}</p>
          <p>Цена: {service.price}</p>
          <div className="rating">
            <span className="rating-text">Рейтинг: {service.rating ? service.rating.toFixed(1) : '0.0'}</span>
            {[1, 2, 3, 4, 5].map((star) => (
              <button key={star} onClick={() => handleRate(star)}>
                {star <= Math.round(service.rating || 0) ? '★' : '☆'}
              </button>
            ))}
          </div>
          <a href={service.serviceUrl} target="_blank" rel="noopener noreferrer" className="service-link">
            Перейти к сервису
          </a>
        </div>
        <div className="comments-section">
          <h2>Комментарии</h2>
          <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
            <option value="newest">Сначала новые</option>
            <option value="oldest">Сначала старые</option>
          </select>
          {renderComments()}
          <div className="add-comment">
            <textarea 
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Добавить комментарий..."
            />
            <button onClick={handleAddComment}>Отправить</button>
          </div>
        </div>
      </div>
      <div className="service-page-footer">
        <Link to="/" className="back-link">Назад к категориям</Link>
      </div>
    </div>
  );
};

export default ServicePage;