import React from 'react';

const ManageServicesPage = ({ services, onDeleteService }) => {
  return (
    <div className="manage-services-page">
      <h2>Управление сервисами</h2>
      <ul>
        {services.map(service => (
          <li key={service.id}>
            {service.name}
            <button onClick={() => onDeleteService(service.id)}>Удалить</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageServicesPage;