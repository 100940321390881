import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const CategoryPage = ({ services, categories }) => {
  const { categoryId } = useParams();
  const [filteredServices, setFilteredServices] = useState([]);
  const [sortBy, setSortBy] = useState('name');

  useEffect(() => {
    const filtered = services.filter(service => service.category === categoryId);
    setFilteredServices(filtered);
  }, [services, categoryId]);

  const sortedServices = [...filteredServices].sort((a, b) => {
    if (sortBy === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortBy === 'rating') {
      return b.rating - a.rating;
    }
    return 0;
  });

  return (
    <div className="category-page">
      <h1>{categories[categoryId]}</h1>
      <div className="sort-controls">
        <label>Сортировать по: </label>
        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value="name">Названию</option>
          <option value="rating">Рейтингу</option>
        </select>
      </div>
      <ul className="services-list">
        {sortedServices.map(service => (
          <li key={service.id} className="service-item">
            <Link to={`/service/${service.id}`}>
              <span className="service-name">{service.name}</span>
              <span className="service-rating">
                {service.rating ? service.rating.toFixed(1) : '0.0'} ★
              </span>
            </Link>
          </li>
        ))}
      </ul>
      <Link to="/" className="back-link">Назад к категориям</Link>
    </div>
  );
};

export default CategoryPage;