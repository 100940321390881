import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelegramUser } from '../hooks/useTelegramUser';

const AddServicePage = ({ onAddService, categories }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [serviceUrl, setServiceUrl] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const user = useTelegramUser();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newService = {
      name,
      description,
      price,
      category,
      serviceUrl,
      addedBy: {
        id: user.id,
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name
      }
    };
    onAddService(newService);
    setIsSubmitted(true);
    setTimeout(() => {
      navigate('/');
    }, 3000);
  };

  if (isSubmitted) {
    return (
      <div className="add-service-page">
        <h1>Сервис отправлен на модерацию</h1>
        <p>Спасибо за добавление сервиса. Он будет проверен модераторами и добавлен в список после одобрения.</p>
        <p>Вы будете перенаправлены на главную страницу через несколько секунд.</p>
      </div>
    );
  }

  return (
    <div className="add-service-page">
      <h1>Добавить новый сервис</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Название:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Описание:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="price">Цена:</label>
          <input
            type="text"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="category">Категория:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Выберите категорию</option>
            {Object.entries(categories).map(([id, name]) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="serviceUrl">Ссылка на сервис:</label>
          <input
            type="url"
            id="serviceUrl"
            value={serviceUrl}
            onChange={(e) => setServiceUrl(e.target.value)}
            required
          />
        </div>
        <button type="submit">Добавить сервис</button>
      </form>
    </div>
  );
};

export default AddServicePage;