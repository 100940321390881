import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = ({ categories }) => {
  return (
    <div className="home-page">
      <h1>Сервисы подписок</h1>
      <Link to="/add-service" className="add-service-button">Добавить новый сервис</Link>
      <h2>Категории сервисов:</h2>
      <ul>
        {Object.entries(categories).map(([id, name]) => (
          <li key={id}>
            <Link to={`/category/${id}`}>{name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HomePage;