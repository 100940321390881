import { useState, useEffect } from 'react';
   import { useTelegramWebApp } from './useTelegramWebApp';

   export const useTelegramUser = () => {
     const [user, setUser] = useState(null);
     const webApp = useTelegramWebApp();

     useEffect(() => {
       if (webApp && webApp.initDataUnsafe.user) {
         setUser(webApp.initDataUnsafe.user);
       }
     }, [webApp]);

     return user;
   };