import React, { useState } from 'react';

const ManageCategoriesPage = ({ categories, onAddCategory, onDeleteCategory }) => {
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newCategoryName.trim()) {
      onAddCategory(newCategoryName.trim());
      setNewCategoryName('');
    }
  };

  return (
    <div className="manage-categories-page">
      <h2>Управление категориями</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          placeholder="Название новой категории"
        />
        <button type="submit">Добавить категорию</button>
      </form>
      <ul>
        {Object.entries(categories).map(([id, name]) => (
          <li key={id}>
            {name}
            <button onClick={() => onDeleteCategory(id)}>Удалить</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageCategoriesPage;