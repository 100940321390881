import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  // Вставьте здесь конфигурацию, полученную из Firebase Console
  apiKey: "AIzaSyDvogZumDVZ9sOfzP1c9oNymywB0V_wPuI",
  authDomain: "subsentry-app.firebaseapp.com",
  databaseURL: "https://subsentry-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "subsentry-app",
  storageBucket: "subsentry-app.appspot.com",
  messagingSenderId: "727535196848",
  appId: "1:727535196848:web:ed570428ee72b6ec2cf975"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;